import { render, staticRenderFns } from "./game-ip-address-edit.vue?vue&type=template&id=53e8179d&scoped=true"
import script from "./game-ip-address-edit.vue?vue&type=script&lang=ts"
export * from "./game-ip-address-edit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e8179d",
  null
  
)

export default component.exports